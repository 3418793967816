import "./GlobalStyle.css"
import AppRouter from "./router"

export default function App() {

return (
   
<AppRouter/>
    
)

}
