
import StyleSaudacao from "./StyleSaudacao.module.css";
import { ObservedElement, useMyContext } from "../../Mycontext/Context";

export default function SectionSaudacao({Titulo,text}){

return(

<>

<section id={StyleSaudacao.Saudacao}>

<ObservedElement id="element19">
<div id={StyleSaudacao.ConteinerSaudacao}>

<div className={StyleSaudacao.ConteinerTextSaudacao}>

<div className={StyleSaudacao.BoxText}>

<h1>{Titulo}</h1>

<p>
{text}
</p>

</div>

</div>

</div>
</ObservedElement>

</section>

</>

)

}